export enum PopupView {
  Hidden = 0,
  PostSend = 1,
  GetQuote = 2,
  Deliver = 3,
  FundWallet = 4,
  PackageDetails = 5,
  SetupPin = 6,
  ForgotPin = 7,
  WithdrawFunds = 8,
  BatchShipping = 9,
  Tracking = 10,
  BookBatchShipping = 11,
  OrderDetails = 12,
  DeliveryCancel = 13,
  BookTravellersRequest = 14,
  TransactionDetails = 15,
  MessageRequestDetails = 16,
  UserRating = 17,
  BuyCredits = 18,
  CompleteKyc = 19,
}

export enum DeliveryType {
  Traveller = 0,
  DispatchPartner = 1,
}

export enum DispatchMode {
  Sprint = 0,
  InterState = 1,
  International = 2,
}
