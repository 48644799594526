import { DispatchBusiness } from './DispatchBusiness';
import Wallet from './Wallet';

export enum KycStatus {
  Unverified = 0,
  Completed = 1,
  Pending = 2,
  Failed = 3,
}

export interface MutedUser {
  mutedUserId: string,
  dateCreated: Date,
}

export interface MyUserInfo {
  fullName: string,
  username: string,
  email: string,
  password: string,
  uid: string,
  id: string,
  idToken: string,
  authProvider: number,
  emailVerified: boolean,
  kycStatus: KycStatus,
  isDispatchPartner: boolean,
  isNigerian: boolean,
  isBlocked: boolean,
  phoneNumber: string,
  phoneVerified: boolean,
  dialCode: string,
  countryOfResidence: string,
  countryCode: string,
  referrer: string,
  currency?: string,
  profilePicture?: string,
  city: string,
  webPushNotificationEnabled: boolean,
  showReadReceipts: boolean,
  emailOnNewNotifications: boolean,
  emailOnDirectMessages: boolean,
  emailPaymentReceipts: boolean,
  emailOrderPerformance: boolean,
  wallet: Wallet,
  business?: DispatchBusiness,
  rating: number,
  numberOfDeliveries: number,
  packagesSent: number,
  mutedUsers: MutedUser[],
}

export enum SignUpStep {
  Identification,
  Password,
  Otp,
  CreateAccount,
  BusinessRepInfo,
  Documents,
  TransactionPin,
}
