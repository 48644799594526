import { ActiveLoader, PluginApi, useLoading } from 'vue-loading-overlay';

const serverAddress: string = process.env.VUE_APP_SERVER_ADDR;
const domainName: string = process.env.VUE_APP_DOMAIN;
const paystackPublicKey: string = process.env.VUE_APP_PAYSTACK_PK;
const cloundinaryUrl: string = process.env.VUE_APP_CLOUDINARY_URL;
const dojahWidgetId: string = process.env.VUE_APP_DOJAH_WIDGET_ID;
const payPalClientId: string = process.env.VUE_APP_PAYPAL_CLIENT_ID;
const autocompleteApiKey: string = process.env.VUE_APP_AUTOCOMPLETE_API_KEY;
const getAxiosConfig = (token: string): any => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  timeout: 60000, // 60 seconds
});

const formatToCurrency = (amount: number, currencySymbol: string) => `
${currencySymbol}${amount.toLocaleString('en-NG', {
  // style: 'currency',
  // currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})}`;

const separateDialCodeAndPadNumber = (phoneNumber: string) => {
  // Regular expression to extract the dial code and main number
  const regex = /^(\+\d{1,3})(\d+)$/;

  const match = phoneNumber.match(regex);

  if (match) {
    const dialCode = match[1];
    const mainNumber = match[2];
    const paddedMainNumber = mainNumber.padStart(11, '0');

    return { dialCode, paddedMainNumber };
  }

  // Return the original number if it doesn't match the expected format
  return { dialCode: '', paddedMainNumber: phoneNumber };
};

const removeLeadingZeros = (val: string): string => {
  let value = val;
  while (value.charAt(0) === '0') {
    value = value.substring(1);
  }
  return value;
};

const truncateString = (str: string, num: number): string => {
  if (!str) {
    return '';
  }
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)} ...`;
};

const getEncodedImageUrl = (url: string) => btoa(url);

const capitalizeText = (text: string): string => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

class Loader {
  private loading: PluginApi;

  private loaderObj: ActiveLoader | undefined;

  constructor() {
    this.loading = useLoading({
      // options
    });
  }

  startLoader = (): void => {
    if (!this.loaderObj) {
      this.loaderObj = this.loading.show({
        // Optional parameters
        canCancel: false, // default false
        color: '#4866af',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      });
    }
  }

  stopLoader = (): void => {
    if (this.loaderObj) {
      this.loaderObj.hide();
      this.loaderObj = undefined;
    }
  }
}

export {
  serverAddress,
  getAxiosConfig,
  domainName,
  Loader,
  formatToCurrency,
  removeLeadingZeros,
  separateDialCodeAndPadNumber,
  truncateString,
  paystackPublicKey,
  capitalizeText,
  cloundinaryUrl,
  dojahWidgetId,
  payPalClientId,
  getEncodedImageUrl,
  autocompleteApiKey,
};
