// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import * as Vue from 'vue';
import { LoadingPlugin } from 'vue-loading-overlay';
import VueGtag from 'vue-gtag';
import 'vue-loading-overlay/dist/css/index.css';
import './registerServiceWorker';
import './index.css';
import timeago from 'vue-timeago3';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Notifications from 'notiwind';
import VueScrollTo from 'vue-scrollto';
import store, { key } from './store';
import router from './router';
import App from './App.vue';
import { firebaseConfig } from './helpers/socialSignOn';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAuth(app);

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
  return undefined;
};

const isEnabled = getCookie('__allowCookie') === '1';

Vue.createApp(App)
  .use(store, key)
  .use(router)
  .use(timeago)
  .use(Notifications)
  .use(LoadingPlugin)
  .use(VueScrollTo)
  .component('VueDatePicker', VueDatePicker)
  .use(VueGtag, {
    bootstrap: isEnabled,
    includes: [{ id: 'G-YG24FN6PRR' }, { id: 'AW-10934017820' }],
  }, router)
  .mount('#app');
