import { getAuth, onAuthStateChanged } from 'firebase/auth';
import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Loader } from '@/helpers/appConfig';
import { processUserToken } from '@/helpers/socialSignOn';
import { SignUpStep } from '@/types/MyUserInfo';

const loaderObj = new Loader();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      authenticationRequired: true,
    },
    component: () => import('../views/DashboardView.vue'),
    children: [
      {
        path: '',
        name: 'DashboardPage',
        component: () => import('../components/DashboardPage.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      authenticationRequired: true,
    },
    component: () => import('../views/DashboardView.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../components/DashboardPage.vue'),
      },
      {
        path: '/wallet',
        name: 'Wallet',
        children: [
          {
            path: 'intl',
            name: 'IntWallet',
            component: () => (
              import('../components/wallet/IntlUserWalletView.vue')),
          },
          {
            path: 'ng',
            name: 'NGWallet',
            component: () => (
              import('../components/wallet/WalletView.vue')),
          },
        ],
      },
      {
        path: '/timeline',
        name: 'Timeline',
        component: () => import('../components/timeline/TimelineView.vue'),
        children: [
          {
            path: '/post/:type/:id',
            name: 'Post',
            component: () => import('../components/timeline/PostView.vue'),
          },
        ],
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('../components/Orders/OrdersView.vue'),
      },
      {
        path: '/messages',
        name: 'Messages',
        component: () => import('../components/messaging/MessagesView.vue'),
        children: [
          {
            path: '/chat',
            name: 'Chat',
            component: () => import('../components/messaging/ChatView.vue'),
          },
        ],
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../components/ProfileView.vue'),
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('../components/activity/ActivityView.vue'),
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import('../components/NotificationsView.vue'),
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('../components/SettingsView.vue'),
        children: [
          {
            path: '',
            name: 'Settings',
            component: () => import('../components/SettingsView.vue'),
          },
          {
            path: '1',
            name: 'AccountSettings',
            component: () => import('../components/settings/AccountSettings.vue'),
          },
          {
            path: '2',
            name: 'NotificationSettings',
            component: () => import('../components/settings/NotificationSettings.vue'),
          },
          {
            path: '3',
            name: 'DirectMessages',
            component: () => import('../components/settings/DirectMessages.vue'),
          },
          {
            path: '4',
            name: 'AdditionalResources',
            component: () => import('../components/settings/AdditionalResources.vue'),
          },
          {
            path: 'account-information',
            name: 'AccountInformation',
            component: () => import('../components/settings/AccountInformation.vue'),
          },
          {
            path: 'change-password',
            name: 'ChangePassword',
            component: () => import('../components/settings/ChangePassword.vue'),
          },
          {
            path: 'muted-users',
            name: 'MutedUsers',
            component: () => import('../components/settings/MutedUsers.vue'),
          },
          {
            path: 'change-pin',
            name: 'ChangePin',
            component: () => import('../components/settings/ChangePin.vue'),
          },
          {
            path: 'email-notifications',
            name: 'EmailNotifications',
            component: () => import('../components/settings/EmailNotifications.vue'),
          },
          {
            path: 'push-notifications',
            name: 'PushNotifications',
            component: () => import('../components/settings/PushNotifications.vue'),
          },
          {
            path: 'deactivate',
            name: 'DeactivateAccount',
            component: () => import('../components/settings/DeactivateAccount.vue'),
          },
          {
            path: 'confirm-password',
            name: 'ConfirmPassword',
            component: () => import('../components/settings/ConfirmPassword.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/verifications',
    name: 'VerificationsView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VerificationsView" */ '../views/VerificationsView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignUp.vue'),
    children: [
      {
        path: '',
        name: 'signup',
        component: () => import('../views/ChooseUserMode.vue'),
      },
      {
        path: 'user-info',
        name: 'user-info',
        component: () => import('../components/SignupStepOne.vue'),
      },
      {
        path: 'creds',
        name: 'Creds',
        component: () => import('../components/SignupStepTwo.vue'),
      },
      {
        path: 'phone-verification',
        name: 'PhoneVerification',
        component: () => import('../components/OTPVerification.vue'),
      },
      {
        path: 'dispatch-partner',
        name: 'dispatch-partner',
        component: () => import('../views/DispatchPartnerSignupViews/CreateAccount.vue'),
      },
      {
        path: 'business-rep',
        name: 'business-rep',
        component: () => import('../views/DispatchPartnerSignupViews/BusinessRepInfo.vue'),
      },
      {
        path: 'security',
        name: 'security',
        component: () => import('../views/DispatchPartnerSignupViews/AuthAndOtherInfo.vue'),
      },
      {
        path: 'transaction-pin',
        name: 'transaction-pin',
        component: () => import('../views/DispatchPartnerSignupViews/TransactionPin.vue'),
      },
      {
        path: '/account-suspended',
        name: 'accountSuspendedView',
        component: () => (
          import('../views/AccountSuspendedView.vue')),
      },
    ],
  },
  {
    path: '/buy-credits',
    name: 'open-buy-tokens',
    component: () => (
      import('../components/wallet/OpenBuyTokens.vue')),
  },
  {
    path: '/paystack-success',
    name: 'paystack-success',
    component: () => (
      import('../components/wallet/PaystackSuccess.vue')),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, from, next) => {
  if (to.path.includes('/verifications') && Object.keys(to.query).length !== 0) {
    if (to.query.oobCode) localStorage.setItem('oobCode', to.query.oobCode.toString());
    if (to.query.mode) localStorage.setItem('mode', to.query.mode.toString());
  }

  if (to.path.includes('/activity') && !store.state.user?.isDispatchPartner) {
    next({ name: 'login' });
  }

  switch (true) {
    case to.path.includes('/dashboard'):
      store.commit('SET_ACTIVE_VIEW', 0);
      break;
    case to.path === '/':
      store.commit('SET_ACTIVE_VIEW', 0);
      break;
    case to.path.includes('/timeline'):
    case to.path.includes('/post'):
      store.commit('SET_ACTIVE_VIEW', 1);
      break;
    case to.path.includes('/wallet'):
      store.commit('SET_ACTIVE_VIEW', 2);
      break;
    case to.path.includes('/orders'):
      store.commit('SET_ACTIVE_VIEW', 3);
      break;
    case to.path.includes('/messages'):
      store.commit('SET_ACTIVE_VIEW', 4);
      break;
    case to.path.includes('/notifications'):
      store.commit('SET_ACTIVE_VIEW', 5);
      break;
    case to.path.includes('/activity'):
      store.commit('SET_ACTIVE_VIEW', 6);
      break;
    case to.path.includes('/profile'):
      store.commit('SET_ACTIVE_VIEW', 7);
      break;
    case to.path.includes('/settings'):
      store.commit('SET_ACTIVE_VIEW', 8);
      break;
    default:
      break;
  }

  // console.log('too', store.state.user, to);
  // if ((to.name === 'Wallet') && store.state.user?.isNigerian) {
  //   next({ name: 'NGWallet' });
  // } else if ((to.name === 'Wallet') && !store.state.user?.isNigerian) {
  //   next({ name: 'IntWallet' });
  // }

  if (to.meta.authenticationRequired && !store.state.isLoggedIn) {
    loaderObj.startLoader();
    try {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        await processUserToken();
        if (store.state.isLoggedIn) {
          if (store.state.user?.isBlocked) {
            next({ name: 'accountSuspendedView' });
          } else if (store.state.user?.isNigerian && !store.state.user?.phoneVerified) {
            store.commit('SET_SIGNUP_STEP', SignUpStep.Otp);
            try {
              await store.dispatch('sendOtp', { email: false, newPhoneNumber: null });
            } catch (error) {
              // do nothing
            }
            next({ name: 'PhoneVerification' });
          } else if (!store.state.user?.isNigerian && !store.state.user?.emailVerified) {
            store.commit('SET_SIGNUP_STEP', SignUpStep.Otp);
            try {
              await store.dispatch('sendOtp', { email: true, newPhoneNumber: null });
            } catch (error) {
              // do nothing
            }
            next({ name: 'PhoneVerification' });
          } else next();
        } else {
          next({ name: 'login' });
        }
        loaderObj.stopLoader();
      });
    } catch (error) {
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

export default router;
